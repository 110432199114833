import React from "react";
import styled from "styled-components";
import { Container } from "@/components/Container";
import { COLORS, TYPOGRAPHY } from "@/constants";
import { Button } from "@/ui/components/Button";
import { Link } from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby";

const NotFound = () => {
  return (
    <Root>
      <StyledContainer>
        <Content>
          {/*<Logo>ЛОГОТИП</Logo>*/}
          <Title>Ошибка 404</Title>
          <p>
            Кажется что-то пошло не так! Страница, которую вы запрашиваете, не
            существует. Возможно она устарела, была удалена, или был введён
            неверный адрес в адресной строке.{" "}
          </p>
          <Button
            onClick={() => navigate(`/`)}
            theme={"primary"}
            className="button"
          >
            Перейти на главную
          </Button>
        </Content>
        <ImageHeartbeat>
          <img src="../../../assets/heartbeat2.svg" alt="heartbeat" />
        </ImageHeartbeat>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.div`
  background: ${COLORS.primaryExtraLight};
  height: 100vh;
`;

const StyledContainer = styled(Container)`
  padding-top: 128px;
  padding-bottom: 120px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.title1Bold64};
  color: ${COLORS.primaryDarkBlue};
  padding-bottom: 24px;
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
`;

const Logo = styled.p`
  color: ${COLORS.primaryBlue};
  ${TYPOGRAPHY.bodyReg22};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageHeartbeat = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export default NotFound;
